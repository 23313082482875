@import "vars";
@import "core/custom-properties";
@import "custom-properties";
@import "dark-theme";
@import "core/reset";
@import "core/all";
@import "core/_positioning.scss";
@import 'core/authorPage';
@import 'core/card';
@import 'core/bigCard';

html {
    overflow-x: hidden;
    font-size: $font-size;
    font-family: $font-family;
    line-height: $line-height;
    color: var(--f-doc-color);
    background-color: var(--f-doc-background-color);
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none !important;
    -khtml-user-select: none;
}

.y-adds-banner {
    position: relative;
    height: auto;
    width: 100%;
    margin: 20px 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    transition: all 0.02s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

ul {
    margin-left: 20px;
}

.text {
    text-indent: 25px;
}

body {
    overflow-x: hidden;
    position: relative;
}

main {
    position: relative;
}

.big_text {
    font-size: 30px;
    margin-top: 30px;
}

.indent {
    margin-top: 50px;
}

@include links() {
    text-decoration: none;
    color: inherit;

    &:hover {
        opacity: 0.8;
    }
}

@include links('', '.primary') {
    color: var(--f-link-color);
    text-decoration: none;
}

@include headings() {
    font-family: $font-family;
    letter-spacing: normal;
}

*[hidden] {
    display: none;
}

.break-word {
    word-break: break-word;
}

.page_container {
    max-width: 1700px;
    width: 100%;
    margin: 0 auto 50px;
    position: relative;
    // background-image: url('../../public/image/back.png');
    background-size: 700px;
}

.component_container {
    margin: 0 150px;
    position: relative;
}

.section {
    position: relative;
    margin-top: 100px;
    // padding-left: 40px;
}

.star {
    top: 280px;
    left: 15px;
    position: absolute;
    width: 4px;
    /*ширина звезды*/
    height: 4px;
    /*высота звезды*/
    background: #fff;
    /*цвет звезды*/
    border-radius: 50%;
    /*закоугление углов*/
    box-shadow: 0 0 0 4px rgba(#F2E205, .1),
        0 0 0 8px rgba(#F2E205, .1),
        0 0 20px rgba(255, 255, 255, 1);
    animation: animate 15s linear infinite;
    z-index: -1;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 300px;
        height: 1px;
        background: linear-gradient(90deg, #fff, transparent);
    }

    &--2 {
        opacity: 0;
        top: 50%;
        animation: animate2 15s linear infinite;
        animation-delay: 4s;
    }
}

.button_container {
    margin-top: 20px;
    width: fit-content;
    @include grid-items-col(2, 10px);
}

.f-loader {
    width: 80px;
    height: 80px;

    @include animation(rotate-360);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    border: 5px solid transparent;
    border-top: 5px solid var(--f-loader);
    border-left: 5px solid var(--f-loader);
    border-radius: 50%;

    position: fixed;
    left: 48%;
    top: 40%;
}

.btn_no_bg {
    display: flex;
    align-items: center;
    font: inherit;
    background: none;
    border: none !important;
    padding: 0 !important;
    min-height: 0 !important;
    color: inherit;
    cursor: pointer;
}

a {
    &:hover {
        color: var(--f-succes-mess-color) !important;
    }
}

button,
a {
    transition: all 0.1s ease;

    & svg {
        display: inline-block;
        margin-right: 5px;
        width: 100%;
        height: 100%;
    }

    &:hover svg {
        fill: var(--f-succes-mess-color);
    }
}

.form-buttons {
    .btn {
        margin-inline-end: 16px;

        &:last-child {
            margin-inline-end: 0;
        }
    }
}

.accent_text {
    color: var(--f-succes-mess-color) !important;
}

.close_icon {
    width: 40px;
    height: 40px;

    position: absolute;
    top: 20px;
    right: 20px;

    // & svg {
    //     fill: var(--icon-fill-white);
    // }
}

.edit__link {
    margin: 20px 0 0 auto;
    display: block;
    width: 40px;
    height: 40px;
}

svg {
    fill: var(--icon-fill-white);
}

.success_mes {
    margin: 40px 0;
    font-size: $fs24;
}

.story_date {
    width: 100%;
    text-align: right;
}

.add_btn {
    & svg {
        width: 20px;
        height: 20px;
    }
}

.story_btns {
    margin: 0 0 0 auto;

    & button {
        width: 40px;
        height: 40px;
    }
}

.story_img {
    width: 100%;
    height: auto;
    max-height: 500px;
    overflow: hidden;


    & img {
        margin: 0 auto;
        display: block;
        // width: 100%;
        min-width: 500px;
        height: auto;
        object-position: center;
        object-fit: cover;
    }

    &--vertical {
        max-height: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
}



.title_link {
    margin-bottom: 30px;
}

.man_with_baterfly {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 400px;
    width: 50%;
    background-image: url('../../public/image/buterflyMan.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
}

.man_baloon {
    position: absolute;
    right: 0;
    top: 550px;
    height: 350px;
    width: 200px;
    background-image: url('../../public/image/flying.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    background-position-y: right;
}

.flower_container {
    position: absolute;
    z-index: -1;
    pointer-events: none;

    &.from_top {
        margin-top: 70px;

        &.right-side {
            margin-top: 300px;
        }
    }

    &.small {
        & .flower {
            width: 65px;
            height: 65px;
        }
    }

    &.middle {
        & .flower {
            width: 80px;
            height: 80px;
        }
    }

    &_one {
        top: 0;

        &.right-side {
            right: 0;
        }
    }

    &_two {
        top: 450px;
        right: -30px;
    }

    &_three {
        bottom: 640px;
        left: -70px;
    }

    &_four {
        top: 100px;
        transform: rotate(175deg);

        &.right-side {
            right: 30px;
        }
    }

    &_five {
        top: 20px;
        left: -90px;
        transform: rotate(90deg);

        &.right-side {
            right: 100px;
            top: 50px;
            left: auto;
            transform: rotate(0);
        }
    }

    @media (max-width: 1280px) {
        display: none;
    }
}

.space-no-wrap {
    white-space: nowrap;
}

.list-types-none {
    margin: 0;
    list-style-type: none !important;
    padding: 0;

    & li {
        list-style-type: none !important;
    }
}

.none {
    display: none;
}

.categories__list {
    height: 100%;
    padding: 70px 50px 20px 120px;

    background: rgba(var(--f-doc-background-color), 1);
    backdrop-filter: blur(20px);
    transform: translateX(-1000px);
    transition: 0.5s all;

    position: absolute;
    z-index: 8;
    top: 0;

    &--open {
        transform: translateX(-160px);
    }
}

.big_img {
    border: 1px solid var(--f-doc-color);
    margin-top: 50px;
    width: 100%;
    overflow-y: hidden;
    height: 300px;
    position: relative;
    // -ms-overflow-style: none;  /* IE и Edge */
    // scrollbar-width: none;  /* Firefox */

    // &::-webkit-scrollbar {
    //     display: none;
    // }

    & img {
        position: absolute;
        top: -200px;
        width: 100% !important;
        height: auto !important;
    }
}

img {
    display: block;
}

.about_me {
    max-width: 60%;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 70% 30%;
    margin: 100px auto 0;

    & img {
        border: 1px solid var(--f-doc-color);
        display: block;
        width: 100% !important;
        height: auto !important;
    }
}

.simple_link {
    font-size: inherit !important;
    display: inline !important;
    width: fit-content !important;
    text-align: left !important;
}

.pagination {
    margin-top: 20px;
    display: flex;

    & li {
        font-size: 1rem;
        padding: 10px;
    }
}

.modal_background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    background: rgba(#040d29, 0.3);
    backdrop-filter: blur(24px);
}

.modal {
    max-width: 500px;
    width: 100%;
    padding: 60px 30px;

    background-color: var(--f-doc-background-color);
    border-radius: var(--f-border-radius);

    text-align: center;
    position: relative;
}

.support_links {
    & a {
        font-size: 2rem;
        display: block;
        margin-top: 20px;
    }
}

.socials_share {
    position: absolute;
    top: 5px;
    right: 0;

    & button {
        margin-right: 10px;
    }
}

.banner {
    margin-top: 40px;
    padding: 20px;
    border-top: 1px solid var(--f-doc-color);
    border-bottom: 1px solid var(--f-doc-color);
    background-repeat: no-repeat;

    position: relative;

    & .back {
        background: var(--f-doc-background-color);
        opacity: 0.7;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    & .text {
        position: relative;
        z-index: 1;
        max-width: 60%;
    }

    & .accent_text {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 38px;
        transform: rotate(30deg);
        z-index: 2;
    }

    &__title {
        display: flex;
        flex-direction: column;

        & span:last-child {
            font-size: 0.8em;
        }
    }

    &.fin_fin {
        background-image: url('../../public/image/banner/1.png'), url('../../public/image/banner/6.png'), url('../../public/image/banner/3.png'), url('../../public/image/banner/5.png'), url('../../public/image/banner/2.png'), ;
        background-position: 0 0, 280px 0, 560px 0, 840px 0, 1120px 0;
        background-size: auto 100%;
    }

    &.goldria {
        background-image: url('../../public/image/goldria/1.png');
        background-position: right -50px;
        background-size: 50%;
    }

    @include media-max(1400px) {
        &.goldria {
            background-position: center;
            background-size: 100%;
        }

    }

    @include media-max($bp-medium) {
        & .text {
            max-width: 100%;
        }

        &.fin_fin {
            background-image: url('../../public/image/banner/3.png'), url('../../public/image/banner/6.png');
            background-position: left, right;
        }
    }

    @include media-max($bp-small) {
        &.fin_fin {
            background-image: url('../../public/image/banner/6.png');
            background-position: center;
            background-size: cover;
        }

        & .accent_text {
            font-size: 20px;
        }
    }
}

@media (min-width: 2000px) {
    .categories__list {
        transform: translateX(-5000px);

        &--open {
            transform: translateX(-160px);
        }
    }
}

@include media-max($bp-large) {
    .edit__link {
        width: 30px;
        height: 30px;
    }

    .star {
        display: none;
    }

    .component_container {
        margin: 0 50px;
    }

    .big_img {
        height: 200px;

        & img {
            top: -100px;
        }
    }
}

@include media-max($bp-xl) {
    .section {
        margin-top: 60px;
    }

    .about_me {
        max-width: 100%;
    }
}

@include media-max($bp-medium) {
    html {
        font-size: $font-size-tablet;
    }

    .component_container {
        margin: 0 50px;
    }

    .man_with_baterfly,
    .man_baloon {
        display: none;
    }
}

@include media-max($bp-small) {
    html {
        font-size: $font-size-mobile;
    }

    .component_container {
        margin: 0 10px;
    }

    .about_me {
        grid-template-columns: 1fr;
    }

    .mobile_opened {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 100vh;
        padding: 80px 30px 30px;
        position: absolute;
        top: 0;
        right: 0;
        background: var(--f-doc-background-color);
        z-index: 10;
        transform: translateX(1000px);
        transition: 0.5s all;

        &--open {
            transform: translateX(0)
        }

        & .author_line,
        & nav,
        & div {
            flex-direction: column;
            align-items: start;
        }

        & .author_line {
            max-height: 100%;
            border: 0;

            &:first-child {
                order: 2;
            }

            &:last-child {
                order: 1;
            }
        }

        & .btn,
        & p {
            margin-bottom: 10px;
        }

        & nav {
            & a {
                padding: 0;
                padding-bottom: 10px;
            }
        }
    }

    .section {
        margin-top: 20px;
    }

    .big_img {
        height: 150px;

        & img {
            top: -50px;
        }
    }

    .edit__link {
        width: 20px;
        height: 20px;
    }

    .categories__list {
        padding: 50px 20px 20px;
        left: -10px;

        &--open {
            transform: translateX(0);
        }
    }
}


@include media-max(360px) {
    .button_container {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 45px);
    }
}