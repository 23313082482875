.big_card {
    padding-top: 30px;

    & h2 {
        text-align: center;
    }

    & a {
        font-size: 24px;
    }
}

.big_card_text {
    width: fit-content;
    margin: 10px auto;
}

.big_card_btns {
    margin: 30px auto 0 !important;
}