.made_new_story {
    text-align: center;
}

.made_new_story__link_container {
    @include center-v-h();
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    height: 30vw;
    // padding: 150px 100px 150px 150px;
    background-image: url('/img/svg/back1.svg'), url('/img/svg/back2.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    // text-align: center;
    // vertical-align: middle;
}

// .comet {
//     position: absolute;
//     max-width: 50%;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     right: 0;
//     border-right: 3px dotted var(--f-succes-mess-color);
//     border-radius: 50%;
//     @include animation(rotate-360);
//     animation-iteration-count: infinite;
//     animation-timing-function: linear;
//     animation-duration: 2s;
// }

.author_page__links {
    & a {
        display: block;
    }
}

.made_new_story__link {
    max-width: 70%;
    width: 100%;
    font-size: calc(16px + 4vw);
}


.made_new_story__desc {
    max-width: 845px;
    width: 100%;
    margin: 60px auto 0;

    font-size: calc(16px + 1.5vw);
}

.premier {
    & span:first-child {
        font-size: calc(16px + 1.5vw);
        font-weight: bold;
    }

    & span:last-child {
        font-size: calc(16px + 1vw);
    }
}

// .start_back {
//     margin: 0 auto;
//     position: absolute;
//     top: 50%;
//     height: 500px;
//     width: 100%;

//     background-image: url('../../../public/image/back.png');
//     background-size: contain;
//     z-index: -1;
// }

.story_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 35px;
}

@include media-max($bp-xl) {
    .made_new_story__link {
        max-width: 80%;
    }

    .story_cards {
        grid-gap: 10px;
    }
}

@include media-max($bp-medium) {
    .made_new_story__link {
        max-width: 70%;
        font-size: calc(16px + 2vw);
    }

    .made_new_story__desc {
       margin-top: 30px;
       font-size: calc(16px + 1vw);
    }

    .premier {
        & span:first-child {
            font-size: calc(16px + 1vw);
        }
    
        & span:last-child {
            font-size: calc(16px + 0.5vw);
        }
    }

    .story_cards {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
}

@include media-max($bp-small) {
    .made_new_story__link {
        font-size: calc(16px + 1vw);
    }

    .made_new_story__desc {
        margin-top: 0;
        font-size: 16px;
    }

    .premier {
        & span:first-child {
            font-size: 16px;
        }
    
        & span:last-child {
            font-size: 15px;
        }
    }
}