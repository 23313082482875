.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: 20px;
    border: 1px solid var(--f-doc-color);
    background-color: var(--f-doc-background-color);
    
    & a {
        width: 100%;
        text-align: center;
        display: block;
        font-size: 24px;
    }

    & ul {
        flex-grow: 1;
        margin-bottom: 40px;
    }

    & .card__title {
        text-align: center;
        min-height: 50px;
    }

    & .text {
        margin-bottom: 40px;
    }
}

.card__list_item {
    margin-top: 10px;
}
@include media-max($bp-medium) {
    .card {
        & a {
            font-size: 1rem;
        }
    
        & .card__title {
            min-height: auto;
        }
    }
}


