@mixin fade-animation($_type, $_from, $_to) {
    @keyframes fade-#{$_type} {
        0% {
            opacity: $_from;
        }
        100% {
            opacity: $_to;
        }
    }
}

@mixin scale-center-fade-animation($_type, $_scaleFrom, $_scaleTo, $_fadeFrom, $_fadeTo) {
    @keyframes scale-center-fade-#{$_type} {
        0% {
            opacity: $_fadeFrom;
            -webkit-transform: scale($_scaleFrom);
            transform: scale($_scaleFrom);
        }
        100% {
            opacity: $_fadeTo;
            -webkit-transform: scale($_scaleTo);
            transform: scale($_scaleTo);
        }
    }
}

@mixin scale-center-fade-in-animation($_type, $_scaleFrom, $_scaleTo) {
    @keyframes scale-center-fade-in-#{$_type} {
        0% {
            opacity: 0;
            -webkit-transform: scale($_scaleTo);
            transform: scale($_scaleTo);
        }
        0.1% {
            opacity: 0;
            -webkit-transform: scale($_scaleFrom);
            transform: scale($_scaleFrom);
        }
        100% {
            opacity: 1;
            -webkit-transform: scale($_scaleTo);
            transform: scale($_scaleTo);
        }
    }
}

@mixin rotate-animation($_type: '', $_from: 0, $_to: 0) {
    @keyframes rotate#{$_type} {
        0% {
            -webkit-transform: rotate($_from);
            transform: rotate($_to);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

@mixin move-x-animation($_type: '', $_from: 0, $_to: 0) {
    @keyframes move-x#{$_type} {
        0% {
            opacity: 0;
            transform: translateX($_from);
        }
        100% {
            opacity: 1;
            transform: translateX($_to);
        }
    }
}

@mixin move-y-animation($_type: '', $_from: 0, $_to: 0) {
    @keyframes move-y#{$_type} {
        0% {
            opacity: 0;
            transform: translateY($_from);
        }
        100% {
            opacity: 1;
            transform: translateY($_to);
        }
    }
}

@mixin simple-show($_type: '', $_from: 0, $_to: 0) {
    @keyframes simpleShow#{$_type} {
        0% {
            transition: translateX($_from);
            visibility: hidden;
            opacity: 0;
        }
    
        100% {
            transition: translateX($_to);
            visibility: visible;
            opacity: 1;
        }
    }
}


