@include buttons('.btn') {
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 700;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: $button-v-padding $button-h-padding;
    border: $button-border-width solid $button-border-color;
    vertical-align: middle;
    line-height: 1;
    min-height: 2rem;
    text-decoration: none;
    white-space: $button-white-space;
    text-align: $button-text-align;
    text-transform: $button-text-transform;
    text-shadow: $button-text-shadow;
    font-weight: 700;
    color: inherit;
    background: $button-bg-color;
    border-radius: 14px;
    border: 1px solid var(--f-doc-color);

    // transition: color $button-transition, background-color $button-transition, border-color $button-transition;
}



@mixin button-hover() {
   opacity: 0.6;
}

@include buttons(':not([disabled]):not(.disabled):not(.no-hover):hover') {
    @include button-hover;
}

@include buttons(':not([disabled]):not(.disabled):not(.no-hover):focus') {
    @include button-hover;
}

// @include buttons(':not([disabled]):not(.disabled):not(.no-hover):active') {
//     color: $button-active-color;
//     background: $button-active-bg-color;
//     border-color: $button-active-border-color;
// }

@mixin disabled-button() {
    // cursor: default;
    // color: $button-disabled-color;
    // background: $button-disabled-bg-color;
    // border-color: $button-disabled-border-color;
    // opacity: $button-disabled-opacity;
}

@include buttons('[disabled]') {
    @include disabled-button;
}

@include buttons('.disabled') {
    @include disabled-button;
}

@include buttons('.full') {
    background: $yellow;
    color: $deep_blue;
    border: 0;
}

.btn {
    &--with_margins {
        margin-top: 20px;
        margin-left: 20px;
    }

    &--margin_top {
        margin-top: 20px;
    }
}
