:root {
    --f-doc-color: #0D0D0D;
    --f-doc-background-color: #efebeb;
    --f-input-background-color: #efebeb;
    --f-header-background-color: #0D0D0D;
    --f-header-border-color: #0d0d0d46;

    --f-succes-mess-color: #A61103;
    --f-loader: #A61103;
    --f-flower-border-color: #0D0D0D;
    --f-flower-bg: #efebeb;
    --f-star-shine: #a6110312;

    .flower {
        width: 100px;
        height: 100px;
        fill: var( --f-flower-bg);
        stroke: var(--f-flower-border-color);
        stroke-width: 0.2px;
        cursor: pointer;

        #Ellipse8,
        #Ellipse10,
        #Ellipse9,
        #Ellipse13,
        #Ellipse12,
        #Ellipse11,
        #Ellipse7,
        #Ellipse6,
        #Ellipse5,
        #Ellipse4,
        #Ellipse15,
        #Ellipse14 {
            transition: all 1s ease;
        }

        // &:hover {
        //     #Ellipse13,
        //     #Ellipse6,
        //     #Ellipse14 {
        //         // transform: translateY(0.5px);
        //         animation: 1s linear 1s infinite alternate flowerone;
        //     }

        //     #Ellipse10,
        //     #Ellipse11,
        //     #Ellipse4 {
        //         // transform: translateX(0.5px);
        //         animation: 1s linear 1s infinite alternate flowertwo;
        //     }

           
        //     #Ellipse9,
            
        //     #Ellipse7,
            
        //     #Ellipse15 {
        //         // transform: translateY(-0.5px);
        //         animation: 1s linear 1s infinite alternate flowerthree;
        //     }

        //     #Ellipse8,
        //     #Ellipse12,
        //     #Ellipse5 {
        //         // transform: translateX(0.7px);
        //         animation: 1s linear 1s infinite alternate flowertwo;
        //     }
        // }
    }

    .flower_moves {
        #Ellipse13,
        #Ellipse6,
        #Ellipse14 {
            // transform: translateY(0.5px);
            animation: 2s linear 1s infinite alternate flowerone;
        }

        #Ellipse10,
        #Ellipse11,
        #Ellipse4 {
            // transform: translateX(0.5px);
            animation: 2s linear 1s infinite alternate flowertwo;
        }

       
        #Ellipse9,
        
        #Ellipse7,
        
        #Ellipse15 {
            // transform: translateY(-0.5px);
            animation: 2s linear 1s infinite alternate flowerthree;
        }

        #Ellipse8,
        #Ellipse12,
        #Ellipse5 {
            // transform: translateX(0.7px);
            animation: 2s linear 1s infinite alternate flowertwo;
        }
    }

    @keyframes flowerone {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(0.5px);
        }
    }

    @keyframes flowertwo {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(0.5px);
        }
    }

    @keyframes flowerthree {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-0.5px);
        }
    }

    @keyframes flowerfour {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(0.7px);
        }
    }
}
