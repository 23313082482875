form {
    .inp, textarea, select, .f-input, .f-select, .f-password-field, .form-field {
        width: 100%;
        min-width: 0;
    }

    .message {
        font-size: 24px;
        line-height: 1.0;
        margin-bottom: 10px;

        &.succes {
            color: var(--f-succes-mess-color);
        } 

        &.error {
            color: var(--f-succes-mess-color) !important;
        }
    }


    label p {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .inp {
        border-radius: $border-radius;
        padding: 15px 20px;
        margin-bottom: 15px;

        &.error {
            border-color: var(--f-succes-mess-color) !important; 
        }
    }
}

.add_history_form {
    & textarea {
        height: 1630px;
    }

    & .text_label {
        display: block;
        margin-top: 15px;
    }
}
