@import "animations-mixins";

@media (prefers-reduced-motion: no-preference) {
    @include move-x-animation('-100', 100px, 0);
    @include move-y-animation('-50', 50px, 0);
    @include move-y-animation('30', -30px, 0);
    @include rotate-animation('-360');
    @include rotate-animation('-30');
    @include fade-animation('in', 0, 1);
    @include fade-animation('out', 1, 0);
    @include scale-center-fade-animation('in', 0.75, 1, 0, 1);
    @include scale-center-fade-animation('out', 1, 0.75, 1, 0);
    @include simple-show('20', -20px, 0);
}

@keyframes stretching {
    0% {
        width: 0;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        width: 100%;
    }
}

@keyframes showLine {
    0% {
        height: 0;
        opacity: 0;
        top: 0;
        bottom: 100%;
    }

    50% {
        opacity: 1;
    }

    100% {
        height: 100%;
        bottom: 0;
    }
}

.showLine {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        background: radial-gradient(#f2f3f4, #F2E205);

        left: 10px;
        top: 0;
        bottom: 0;

        animation-name: showLine;
        animation-duration: 1s;
    }
}

@keyframes animate{
    0%
    {
        transform: rotate(175deg) translateX(0);
    }
    10%
    {
        opacity: 1;
    }
    20%
    {
        transform: rotate(175deg) translateX(-1000px);
        opacity: 0;
    }

    100%
    {
        transform: rotate(175deg) translateX(-1000px);
        opacity: 0;
    }
}  

@keyframes animate2 {
    0%
    {
        transform: rotate(200deg) translateX(0);
    }
    10%
    {
        opacity: 1;
    }
    20%
    {
        transform: rotate(200deg) translateX(-2000px);
        opacity: 0;
    }

    100%
    {
        transform: rotate(200deg) translateX(-2000px);
        opacity: 0;
    }
}  

// .showBlock {
//     animation-name: showLine;
//     animation-duration: 1s;
// }



