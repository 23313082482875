

$error-color-lightest: #f9d0d9;
$error-color-lighter: #f2a2b3;
$error-color-light: #e95c7b;
$error-color: #FD2E63;
$error-color-dark: #9c0f2e;
$error-color-darker: #59091a;
$error-color-darkest: #2d040d;

$yellow: #F2E205;
$orange: #F2CB05;
$red: #A61103;

$white: #f2f3f4;
$grey-color-light: #A6A6A6;
$grey-color: #595959;
$dark-grey: #404040;
$black-color: #0D0D0D;
$deep_blue: #040d29;
