@import "./color-palette";
@import "./vars";

:root.dark-theme {
    // --button-bg-color: #0D0D0D;
    // --f-doc-background-color: #0D0D0D;
    // --f-input-background-color: #0D0D0D;
    --button-bg-color: #050516;
    --f-doc-background-color: #050516;
    --f-input-background-color:#050516;
    --f-doc-color: #f2f3f4;
    --f-primary-color: $yellow;
    --f-header-border-color: #f2f3f456;
    --f-succes-mess-color: #F2E205;
    --f-error-mess-color: #A61103;
    --f-loader: #f2f3f4;

    --f-border-radius: 20px;

    --icon-fill-white: #f2f3f4;
    --f-star-shine: #f2e20515;

    .inp {
        color: $white;
        border: 1px solid $white;
        background-color: var(--f-input-background-color) !important;

        & input {
            background: none;
            border: none;
            height: 100%;
            width: 93%;
            color: $white;

            &:focus {
                border: none;
                outline: none;
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        box-shadow: 0 0 0 30px #0D0D0D inset !important;
        color: var(--f-loader);
        -webkit-text-fill-color: var(--f-loader);
    }

    .flower {
       display: none; 
    }

    .modal {
        background-image: url('../../public/image/on_the_moon.png');
        background-repeat: no-repeat;
        background-size: auto 90%;
        background-position: 20px center;
    }

}


