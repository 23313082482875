

/**
 * Style used in media queries loop.
 *
 * @param $_bp Breakpoint shortcut.
 */
@mixin layout-media($_bp: '') {
    .reverse-#{$_bp} {
        flex-direction: row-reverse;
    }

    .reverse-#{$_bp}.columns {
        flex-direction: column-reverse;
    }

    .first-#{$_bp} {
        order: -1;
    }

    .last-#{$_bp} {
        order: 1;
    }

    .no-offset-#{$_bp} {
        margin-inline-start: 0;
    }

    @include flexbox-rows-cols('-#{$_bp}');
}


.flexbox-rows {
    display: flex;

    &:not(.no-wrap) {
        flex-wrap: wrap;
    }

    &.space-between {
        @include center-space-between();
    }

    &.columns {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &.narrow-container {
        padding: 0;
    }


    &.equal-height {
        align-items: stretch;

        @include flexbox-cols('> ', ' > *:not(.col):not([class^="col-"]):not([class*=" col-"])') {
            height: 100%;
        }
    }

}

@include flexbox-cols() {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}


.align-items-start {
    align-items: start;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
    //align-items: end;
}

.reverse {
    flex-direction: row-reverse;
}

.reverse.columns {
    flex-direction: column-reverse;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.first {
    order: -1;
}

.last {
    order: 1;
}

.no-row-margins {
    @include no-row-margins;
}

.no-gutters {
    @include no-row-margins;

    @include flexbox-cols('> ') {
        padding-inline-end: 0;
        padding-inline-start: 0;
    }
}

// loop through all media query breakpoints
//@debug " ---- WTF? ---- #{$breakpoints-map}";
@each $shortcut, $breakpoint in $breakpoints-map {
    @include media-max($breakpoint) {
        @include layout-media($shortcut);
    }
}
